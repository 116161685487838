import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const TripListElementStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
  padding: 0.5rem;
  border: 1px solid var(--gray);
  border-radius: 0.4rem;
  box-shadow: 0 0 0 1px rgba(49, 49, 93, 0.03),
    0 2px 5px 0 rgba(49, 49, 93, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  &:hover {
    border-color: var(--red);
  }
  p {
    margin: 0.5rem 0 0 0;
  }
  h2 {
  }
  a {
    font-size: 2.5rem;
    color: white;
    text-decoration: none;
    display: block;
    &:hover {
      color: var(--red);
    }
  }
`;

export function TripListElement(props) {
  const { link, place, date, image, altText } = props;
  return (
    <Link to={`/${link || date}`}>
      <TripListElementStyles>
        <img src={image} alt={altText} />
        <h2>{place}</h2>
        <p>{date}</p>
      </TripListElementStyles>
    </Link>
  );
}
